import React from "react"
// import PropTypes from 'prop-types';

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import "./white-paper-form.scss"
import FormsService from "../service/formsservice"

export default class WhitePaperForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      formData: {},
    }
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    this.setState({ submitting: true })
    let data = JSON.stringify(this.state.formData)
    FormsService.whitePaperForm(data)
      .then((res) => {
        if (res) {
          this.setState({ submitted: true })
        }
      })
      .catch((error) => console.log("Error: ", error))
      .finally(() => {
        this.setState({ submitting: false })
      })
  }

  render() {
    const { submitting, submitted } = this.state

    return (
      <div className="wrapperWhitePaper">
        <Container className="customContainerWhitePaper">
          <div className="text-sectionWhitePaper text-center">
            <h4 className="h2">Request White Paper</h4>
            <p>
              The SHAPE White Paper details the cutting-edge models, evidence
              base and business intelligence that power SHAPE.
              <br />
              <br />
              If you wish to access the SHAPE white paper, please provide your
              details below.
            </p>
          </div>
          <div className="formCardWhitePaper">
            <form
              name="white-paper"
              id="white-paper-form"
              action="/thanks/"
              onSubmit={this.handleSubmit}
              disabled={submitted}
            >
              <Row>
                <Col sm={12}>
                  <div className="input-parent">
                    <label>Your full name *</label>

                    <input
                      required
                      type="text"
                      name="fullName"
                      id="white-paper-fullname"
                      disabled={submitted}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="input-parent">
                    <label>Your email address *</label>

                    <input
                      required
                      type="text"
                      name="email"
                      id="white-paper-email"
                      disabled={submitted}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="input-parent">
                    <label>Your company name *</label>

                    <input
                      required
                      type="text"
                      name="companyName"
                      id="white-paper-company"
                      disabled={submitted}
                      className="form-control"
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="input-parent">
                    <label>Additional comments</label>

                    <textarea
                      name="comments"
                      id="white-paper-comments"
                      className="form-control"
                      onChange={this.handleChange}
                      disabled={submitted}
                      rows={6}
                      maxLength={500}
                    />
                  </div>
                </Col>
              </Row>

              <div className="text-center">
                {submitted && (
                  <div className="submittedSuccess">
                    Thank you. We’ll be in touch very shortly.
                  </div>
                )}
                <button
                  id="white-paper-form-button"
                  type="submit"
                  disabled={submitting || submitted}
                >
                  {(submitting && "Submitting...") ||
                    (submitted && "Submitted") ||
                    "Submit"}
                </button>
              </div>
            </form>
          </div>
        </Container>
      </div>
    )
  }
}
